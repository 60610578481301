import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { performUncatchedRequest } from '../utils/request';
import { successPopAlert } from '../components/PopAlert';
import { SimpleObject } from '../types';

type ParameterType = { _id: string; paramName: string; paramValue: string; isGlobal: boolean };

const ParametersPage: FunctionComponent<{}> = () => {
  const [state, setState] = useState<SimpleObject>({
    chatbot_url: '' as string
  });

  const [parametersData, setParametersData] = useState<ParameterType[]>([]);
  const [loading, setLoading] = useState(false);

  const setter = (param: string) => (newValue: any) =>
    setState((prevState) => ({ ...prevState, [param]: newValue }));

  const paramSetterObject = {
    chatbot_url: setter('chatbot_url')
  } as SimpleObject;

  const handleInputChange =
    (callback: (param: any) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      callback(event.target.value);
    };

  const getParameters = async () => {
    setLoading(true);
    const { data } = await performUncatchedRequest({
      endpoint: 'parameters',
      method: 'get'
    });

    data.forEach((param: ParameterType) => {
      if (paramSetterObject[param.paramName]) {
        paramSetterObject[param.paramName](param.paramValue);
      }
    });
    setParametersData(data);
    setLoading(false);
  };

  useEffect(() => {
    getParameters();
  }, []);

  const handleGuardar = async () => {
    // Enviar datos al backend
    try {
      setLoading(true);
      await performUncatchedRequest({
        endpoint: 'parameters/global',
        method: 'patch',
        query: { chatbotURL: state.chatbot_url }
      });

      await getParameters();
      successPopAlert('Parametros guardados correctamente!');
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: {
              key: 'auction-parameters-admin',
              icon: 'cogs',
              content: 'Paramentros de Subasta'
            },
            render: () => (
              <Tab.Pane attached={false}>
                <div>
                  <h1>Parametros de Globales</h1>

                  {loading ? (
                    <b>cargando...</b>
                  ) : (
                    <div style={{ marginTop: '3em', marginLeft: '3em' }}>
                      <table>
                        <tbody>
                          {parametersData
                            .filter((p) => p.isGlobal)
                            .map((d, i) => (
                              <tr key={i}>
                                <td>
                                  <label>{d.paramName}</label>
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    style={{ width: '800px' }}
                                    value={state[d.paramName]}
                                    onChange={handleInputChange(paramSetterObject[d.paramName])}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <div style={{ marginTop: '3em', marginLeft: '10em' }}>
                        <button onClick={handleGuardar}>Guardar</button>
                      </div>
                    </div>
                  )}
                </div>
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default ParametersPage;
