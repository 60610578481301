import React, { useState, useEffect, useRef } from 'react';
import { errorPopAlert } from './PopAlert';
import './ChatComponent.css'; // add a separate CSS file for styling
import { performUncatchedRequest } from '../utils/request';

const ChatComponent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(''); // user's input
  const [conversation, setConversation] = useState<Array<string[]>>([]); // conversation history
  const [loading, setLoading] = useState(false); // API request loading state
  const [showChat, setShowChat] = useState(false); // toggle chat visibility

  useEffect(() => {
    // initialize conversation history
  }, []);

  const handleInputChange = (event: any) => {
    if (!loading) {
      setInputValue(event.target.value);
    }
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter' && !loading) {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    try {
      setConversation((prevConversation) => [...prevConversation, [inputValue]]);

      const response = await performUncatchedRequest({
        endpoint: 'chatbot',
        method: 'post',
        data: { chat: inputValue }
      });

      if (!response) {
        setInputValue('');
        setLoading(false);
        return;
      }

      setConversation((prevConversation) => {
        prevConversation[prevConversation.length - 1].push(response.data.pregunta_respuesta[1]);
        return prevConversation;
      });

      setInputValue('');
      setLoading(false);
    } catch (error: any) {
      errorPopAlert(
        'Error al intentar comunicarse con el chatbot, por favor intente nuevamente',
        error.message
      );

      if (conversation[conversation.length - 1][0] && !conversation[conversation.length - 1][1]) {
        setConversation((prevConversation) => {
          prevConversation[prevConversation.length - 1].push(
            'Hubo un error al intentar responder, por favor intente nuevamente...'
          );
          return prevConversation;
        });
      }
      console.error(error);
      setInputValue('');
      setLoading(false);
    } finally {
      inputRef.current?.focus();
    }
  };

  const handleToggleChat = () => {
    setShowChat(!showChat);
  };

  const handleCleanChat = () => {
    setConversation([]);
  };

  return (
    <div>
      {showChat ? (
        <div className='chat-window'>
          <button className='hide-chat' onClick={handleToggleChat}>
            Ocultar
          </button>
          <button className='clean-chat' onClick={handleCleanChat}>
            limpiar
          </button>
          <div className='conversation-panel'>
            <ul>
              {conversation.map((item, index) => (
                <li style={{ marginBottom: '1em' }} key={index}>
                  <span>
                    <b>{item[0]}</b>
                  </span>{' '}
                  <br />
                  {item[1] ? (
                    <span> {item[1]}</span>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>esperando respuesta...</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* {loading ? <p>cargando...</p> : null} */}
          <div className='input-panel'>
            <input
              ref={inputRef}
              type='text'
              disabled={loading}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnter}
            />
            <button disabled={loading} onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      ) : (
        <button className='toggle-chat' onClick={handleToggleChat}>
          Open Chat
        </button>
      )}
    </div>
  );
};

export default ChatComponent;
