import React, { FunctionComponent } from 'react';
import { Socket } from 'socket.io-client';
import { AuthState } from '../reducers/types';
import './css/home.css';
//import HistoricalPriceChart from '../components/HistoricalPriceChart';
import HistoricalSalePriceChart from '../components/HistoricalSalePriceChart';
import News from '../components/News';
//import PriceList from '../components/PriceList';
import ProductStorePage from './ProductStorePage';

interface IProps {
  auth: AuthState;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
  socket: Socket | null;
  setSocket: React.Dispatch<React.SetStateAction<Socket | null>>;
}

const HomePage: FunctionComponent<IProps> = ({ auth, setPageContentName, socket, setSocket }) => {
  return (
    <div className='home-container'>
      <div className='home-top-container'>
        <div className='home-historical-price-container'>
          {/* <HistoricalPriceChart /> */}
          <HistoricalSalePriceChart />
        </div>
        <div className='home-news-price-list-container'>
          <News setPageContentName={setPageContentName} />
          {/* <PriceList setPageContentName={setPageContentName} /> */}
        </div>
      </div>
      <div className='home-bottom-container'>
        <ProductStorePage isAddToTrolleyAllowed={false} />
      </div>
    </div>
  );
};

export default HomePage;
