import 'bootstrap/dist/css/bootstrap.min.css';
import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser } from '../actions/authActions';
import '../css/AppHome.css';
import Pages from '../pages';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { AppLink } from '../components/types';
import { StockMovementAuditCRUD } from './GeneratedComponents';
import AuthorizationPage from '../pages/AuthorizationPage';

export const adminLinks: AppLink[] = [
  {
    href: '/control-panel/home',
    name: 'Home',
    component: Pages.HomeControlPanelPage
  },
  {
    href: '/control-panel/mantenedores',
    name: 'Mantenedores',
    component: Pages.MantenedoresPage
  },
  {
    href: '/control-panel/sku-admin',
    name: 'Administracion Precios',
    component: Pages.SkuAdminPage
  },
  {
    href: '/control-panel/stock-load-admin',
    name: 'Administracion Stock',
    component: Pages.StockLoadAdminPage
  },
  {
    href: '/control-panel/lot-stock-admin',
    name: 'Administracion Lote',
    component: Pages.LotStockAdminPage
  },
  {
    href: '/control-panel/completar-orden-admin',
    name: 'Completar Ordenes',
    component: Pages.OrderCompletionPage
  },
  {
    href: '/control-panel/auction-admin',
    name: 'Administracion de subastas',
    component: Pages.AuctionAdminPage
  },
  {
    href: '/control-panel/ventas-realizadas',
    name: 'Ventas realizadas',
    component: Pages.SoldCartsPage
  },
  {
    href: '/control-panel/ventas-usuarios',
    name: 'Ventas por usuarios',
    component: Pages.CartPerUserPage
  },
  {
    href: '/control-panel/confirmacion-ventas',
    name: 'Finalizar Ventas',
    component: Pages.SalesManagementPage
  },
  {
    href: '/control-panel/devoluciones',
    name: 'Devoluciones',
    component: Pages.SaleReturnPage
  },
  {
    href: '/control-panel/descartados',
    name: 'Descartados',
    component: Pages.DiscardedStockPage
  },
  {
    href: '/control-panel/parametros-globales',
    name: 'Parametros Globales',
    component: Pages.GlobalParametersPage
  },
  {
    href: '/control-panel/parametros-subasta',
    name: 'Parametros de Subasta',
    component: Pages.ParametersPage
  },
  {
    href: '/control-panel/parametros-venta',
    name: 'Reglas de Ventas',
    component: Pages.SaleOptionsPage
  },
  {
    href: '/control-panel/buscador-barcode',
    name: 'Buscador Barcode',
    component: Pages.BarcodeSearchPage
  },
  {
    href: '/control-panel/registros-auditoría',
    name: 'Registro Auditoria Stock',
    component: StockMovementAuditCRUD
  },
  {
    href: '/control-panel/generacion-clave-autorizacion',
    name: 'Generar Clave Autorizacion',
    component: AuthorizationPage
  }
];

export const operadorLinks: AppLink[] = [
  {
    href: '/control-panel/price-list',
    name: 'Lista de Precios',
    component: Pages.PriceListAdminPage
  }
];

export const autoRoutes = () =>
  [...adminLinks, ...operadorLinks].map(({ component, href }, k) => (
    <Route key={`main_menu_${k}`} exact path={href} component={component} />
  ));

type Props = {
  loadUser: () => Promise<void>;
  auth: AuthState;
};

const App: FunctionComponent<Props> = ({ loadUser, auth }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <Router>
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>
      <Switch>
        <Route exact path={'/'} component={Pages.AnonimousPage} />
        <Route exact path={'/subasta'} component={Pages.AuctionPage} />
        <Route path='/control-panel' component={Pages.ControlPanelPage} />
        {/* TODO: 404 page should be more elaborated */}
        <Route path='/404' component={Pages.NotFoundPage} />
        <Redirect to='/404' />
      </Switch>
    </Router>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser })(App);
